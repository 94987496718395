export const safeLocalStorage = {
  setItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e: unknown) {
      console.error('Failed to save in localStorage:', e);
    }
  },

  getItem(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (e: unknown) {
      console.error('Failed to get item from localStorage:', e);
      return null;
    }
  },

  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e: unknown) {
      console.error('Failed to remove item from localStorage:', e);
    }
  }
};
